import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import { Link } from 'gatsby';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="NotFoundPage Page Title" />
        <div id="main">
          <section id="content" className="main">
            <h2>Fuck me, this page doesn't exist.</h2>
            <p>
              I know it doesn't like it, but really this page doesn't exist.
            </p>
            <ul className="actions">
              <li>
                <Link to="/" className="button special">
                  Take me home
                </Link>
              </li>
            </ul>
          </section>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
